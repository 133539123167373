import logo from './logo.svg';
import './App.css';
import palm from './assets/palmera-maxima.png';

function App() {
  return (
    <div className="App">
      <figure>
        <div class="stripes-wraper">
          <div className="stripe"></div>
          <div className="stripe"></div>
          <div className="stripe"></div>
          <div className="stripe"></div>
          <div className="stripe"></div>
          <div className="stripe"></div>
          <div className="stripe"></div>
          <div className="stripe"></div>
          <div className="stripe"></div>
          <div className="stripe"></div>
          <div className="stripe"></div>
          <div className="stripe"></div>
          <div className="stripe"></div>
          <div className="stripe"></div>
          <div className="stripe"></div>
          <div className="stripe"></div>
        </div>
        <div className="gradient-mask"></div>
        <img src={palm} className="palm-tree"/>
      </figure>
    </div>
  );
}

export default App;
